<template>
  <div class="w-voicy-holder">
    <w-highlighted
      v-if="highlighted"
      :card-options="cardOptions"
      :content="content"
    />
    <w-default v-else :card-options="cardOptions" :content="content" />
  </div>
</template>

<script>
import WHighlighted from './WHighlighted'
import WDefault from './WDefault'

export default {
  components: {
    WHighlighted,
    WDefault,
  },

  props: {
    cardOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    content: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
