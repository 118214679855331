<i18n>
{
  "fr": {
    "see_more": "Show me more"
  },
  "fr": {
    "see_more": "En voir plus"
  }
}
</i18n>

<template>
  <v-container fluid class="w-voicy-list pa-0">
    <w-div-loader v-if="isLoading || isLoadingMore" />
    <portal to="before-container">
      <div
        v-if="hasHighlightedVoice"
        class="d-flex justify-center"
        :class="{
          'mt-0': $voicer.isDesktop,
          'mb-3': $voicer.isMobile,
          'pb-4 mb-4': $voicer.isIE,
        }"
      >
        <w-voicy-card
          :card-options="{
            flat: true,
            tile: true,
          }"
          :content="highlightedVoice"
          highlighted
        />
      </div>
    </portal>
    <v-container
      :class="{
        'pa-0': $voicer.isDesktopOrTablet,
        'pl-4 pr-4 pt-0': $voicer.isMobile,
        'pt-4 mt-4': $voicer.isIE,
      }"
      grid-list-md
    >
      <v-layout row wrap class="pa-1">
        <v-flex
          v-if="
            isLoading === false && isLoadingMore === false && noContent === true
          "
          lg12
        >
          <slot name="empty" />
        </v-flex>
        <v-flex v-for="voice in voices" :key="voice.__uid__" xs12 sm6 md4 lg4>
          <w-voicy-card :content="voice" />
        </v-flex>
      </v-layout>
      <div class="w-voicy-list__bottom">
        <v-btn
          v-if="hasMore"
          :loading="isLoading || isLoadingMore"
          block
          color="primary"
          depressed
          large
          @click="handleMore"
        >
          {{ $t('see_more') }}
        </v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import WDivLoader from '~/components/WDivLoader'
import WVoicyCard from '~/components/WVoicyCard'

export default {
  components: {
    WDivLoader,
    WVoicyCard,
  },

  props: {
    contents: {
      type: Array,
      required: true,
      default: () => [],
    },

    hasMore: {
      type: Boolean,
      required: true,
      default: () => false,
    },

    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    loadMore: {
      type: Function,
      required: true,
      default: (_) => _,
    },
  },

  data: () => ({
    isLoadingMore: false,
  }),

  computed: {
    hasHighlightedVoice() {
      return this.highlighted && this.contents.length > 0
    },
    highlightedVoice() {
      return this.contents[0]
    },
    voices() {
      if (this.hasHighlightedVoice) {
        // eslint-disable-next-line
        const [highlightedContent, ...othersContents] = this.contents

        return othersContents
      } else {
        return this.contents
      }
    },
    noContent() {
      if (this.hasHighlightedVoice) {
        return false
      }
      return this.voices.length === 0
    },
  },

  methods: {
    async handleMore() {
      this.isLoadingMore = true

      try {
        await this.loadMore()
      } catch (e) {
        this.$voicer.captureException(e)
      } finally {
        this.isLoadingMore = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-voicy-list {
  &__bottom {
    margin-top: 14px;
    padding-top: 14px;

    button {
      max-width: 280px;
      margin: 0 auto;
    }
  }
}
</style>
