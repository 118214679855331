<i18n>
{
  "en": {
    "home": "Home",
    "404": "This page does not exist",
    "404_explain": "The page you are trying to access does not exist.",
    "unknown": "Unknown error",
    "unknown_explain": "The application encountered a problem, our technical team is currently working on solving this issue."
  },
  "fr": {
    "home": "Accueil",
    "404": "Cette page n'existe pas",
    "404_explain": "La page à laquelle vous tentez d'accéder n'existe pas ou a été déplacée.",
    "unknown": "Erreur inconnue",
    "unknown_explain": "L'application a rencontré un problème, nos équipes techniques tentent de résoudre le plus rapidement possible cette erreur."
  }
}
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-error--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-error"
    :style="$voicer.getThemeConfigProperty('components.app.style')"
    no-title
  >
    <template v-slot:content>
      <p class="body-2">
        {{ explain }}
      </p>
    </template>
    <template v-slot:actions>
      <v-spacer />
      <v-btn color="primary" depressed nuxt to="/">
        {{ $t('home') }}
      </v-btn>
    </template>
  </w-layout-modal>
</template>

<script>
import ExcentricErrorLayoutMixin from '~/excentrics/mixins/layouts/error'

export default {
  layout: 'minimal',
  components: {},
  props: {
    error: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    rootClass() {
      return {
        'w-page': true,
        [`w-page--${this.error.statusCode}`]: true,
      }
    },
    title() {
      if (this.error.statusCode === 404) {
        return this.$t('404')
      }

      return this.$t('unknown')
    },

    explain() {
      if (this.error.statusCode === 404) {
        return this.$t('404_explain')
      }

      return this.$t('unknown_explain')
    },
  },
  mounted() {
    ExcentricErrorLayoutMixin.mounted.call(this)
  },
}
</script>

<style lang="scss" scoped>
.w-page {
  padding: 5em 0;

  h2,
  p {
    margin-bottom: 0.75em;
  }
}
</style>
