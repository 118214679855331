import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      playerContentId: (state) => state.player.content.id,
    }),
    ...mapGetters({
      isError: 'player/isError',
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
    }),
    isLoadingContent() {
      return this.isLoading === true && this.isSelectedContent
    },
    isSelectedContent() {
      return this.content.id === this.playerContentId
    },
    isPlayingContent() {
      const content = this.content

      return (
        this.isLoading === false &&
        this.isPlaying &&
        content.id === this.playerContentId
      )
    },
  },
}
