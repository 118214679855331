import axios from 'axios'
import lowdb from 'lowdb'

import DocumentComment from './models/brocoli/Comment'
import DocumentContent from './models/brocoli/Content'
import DocumentConversation from './models/brocoli/Conversation'
import DocumentMedia from './models/brocoli/Media'
import DocumentPersona from './models/brocoli/Persona'
import DocumentSession from './models/brocoli/Session'
import DocumentSite from './models/brocoli/Site'
import DocumentTag from './models/brocoli/Tag'
import DocumentUser from './models/brocoli/User'

import Analytics from './services/Analytics'
import Authenticate from './services/Authenticate'
import Collection from './services/Collection'
import EventManager from './services/EventManager'
import Garbage from './services/Garbage'
import MediaPlayer from './services/MediaPlayer'
import Messager from './services/Messager'
import Model from './services/Model'
import PseudoQueryFactory from './services/PseudoQuery'
import Realtime from './services/Realtime'
import Register from './services/Register'
import getSiteInstance from './services/Site'

import Config from './utils/config'
import isBrowser from './utils/is-browser'
import ConfigurationError from './utils/errors/ConfigurationError'
import ConceptionError from './utils/errors/ConceptionError'
import NetworkError from './utils/errors/NetworkError'
import getters from './utils/getters'
import models from './utils/models'
import NetworkDetection from './utils/network-detection'
import nextTick from './utils/next-tick'

import {
  DEFAULT_CONFIGURATION,
  DEFAULT_APIS_ENDPOINTS,
  DEFAULT_APIS_VERSIONS
} from './defaults'

const version = '2.1.0'
const noop = (_) => _

class Core extends Config {
  /**
   * @api private
   * @description currently applyed configuration
   */
  #activeConfiguration = null

  /**
   * @api private
   * @description
   * list of collections
   * @see collections accessor
   */
  #collections = {
    comments: DocumentComment,
    contents: DocumentContent,
    conversations: DocumentConversation,
    medias: DocumentMedia,
    personaes: DocumentPersona,
    sessions: DocumentSession,
    sites: DocumentSite,
    tags: DocumentTag,
    users: DocumentUser
  }

  /**
   * @api public
   * @description true if spoke is runned via node
   */
  isNode = !isBrowser

  /**
   * @api public
   * @class Model
   * @description
   * Accessor for the Model builder
   */
  Model = Model

  /**
   * @api public
   * @class Register
   * @description
   * Accessor for the Service Register
   */
  Register = Register

  /**
   * @api public
   * @description
   * Analytics instance
   * @returns {Analytics} instance
   */
  analytics = Analytics

  /**
   * @api public
   * @description
   * the last Authenticate instance
   * @see #authenticate()
   * @returns {Authenticate} instance
   */
  authentication = Authenticate

  /**
   * @api public
   * accessor of EventManager singleton
   * @see EventManager
   * @returns {EventManager} instance
   */
  eventManager = EventManager

  /**
   * @api public
   * @description
   * accessor of fetch instance
   */
  http = axios.create({
    timeout: 8000
  })

  /**
   * @api public
   * @description
   * `true` if spoke is well configured and ready
   */
  isReady = false

  /**
   * @api public
   * @description
   * get the mediaplayer singleton instance
   * @returns {MediaPlayer} instance
   */
  mediaplayer = MediaPlayer

  /**
   * @api public
   * @description
   * get a Messager class object
   */
  Messager = Messager

  /**
   * @api public
   * @description
   * network detection utils
   * usefull for a future usage (replay post action for example)
   */
  network = NetworkDetection

  /**
   * @api public
   * @instance realtime
   * @description
   * Accessor for the Service Realtime
   * @returns {Realtime} instance
   */
  realtime = Realtime

  /**
   * @api public
   * @description
   * the last Register instance
   * @see #register()
   * @returns {Register} instance
   */
  registration = null

  /**
   * @api public
   * @description
   * site document
   * @returns {Model<Site>}
   */
  site = getSiteInstance()

  /**
   * @api public
   * @description
   * instance of store
   * @returns {lowdb} instance
   */
  store = null

  /**
   * @api public
   * @instance user
   * @description
   * Accessor for the User singleton instance
   */
  user = null

  /**
   * @api public
   * @property isBrowser
   * @description true if the current instance is runned on the browser
   */
  get isBrowser() {
    return this.isNode === false
  }

  /**
   * @api public
   * @property version
   * @description
   * Will return the current package version from the package.json file
   * @return {string}
   */
  get version() {
    return version
  }

  constructor() {
    super()

    if (this.isNode) {
      const Memory = require('lowdb/adapters/Memory')

      this.store = lowdb(new Memory())
    } else {
      let store

      try {
        const LocalStorage = require('lowdb/adapters/LocalStorage')
        store = new LocalStorage('spoke')
      } catch (error) {
        const Memory = require('lowdb/adapters/Memory')
        store = new Memory()
      } finally {
        this.store = lowdb(store)
      }
    }

    this.set(DEFAULT_CONFIGURATION)

    const commonsDefaultsProperties = {
      __config: {
        get: () => {
          return this.configuration
        },
        configurable: false,
        enumerable: false
      },
      // @todo: fix this ugly shit
      __getConfig: {
        get: () => {
          return this.get
        },
        configurable: false,
        enumerable: false
      },
      __http: {
        set: () => {
          throw new CoreError('__http is not a writable accessor')
        },
        get: () => {
          return this.http
        },
        enumerable: false,
        configurable: false
      },
      __site: {
        set: () => {
          throw new CoreError('__site is not a writable accessor')
        },
        get: () => {
          return this.site
        },
        enumerable: false,
        configurable: false
      },
      __store: {
        set: () => {
          throw new CoreError('__store is not a writable accessor')
        },
        get: () => {
          return this.store
        },
        enumerable: false,
        configurable: false
      },
      __user: {
        set: () => {
          throw new CoreError('__user is not a writable accessor')
        },
        get: () => {
          return this.user
        },
        enumerable: false,
        configurable: false
      }
    }

    this.user = new DocumentUser()
    this.site = getSiteInstance()

    // singletons associations
    const Singletons = [Authenticate, EventManager]
    Singletons.map((instance) =>
      Object.defineProperties(instance, commonsDefaultsProperties)
    )

    // factories associations
    const Factories = [Register, Collection, Model]
    Factories.map((klass) =>
      Object.defineProperties(klass.prototype, commonsDefaultsProperties)
    )

    this.http.interceptors.response.use((response) => {
      return response
    })
    this.http.interceptors.response.use(
      (response) => response.data.data,
      (error) => {
        return Promise.reject(
          error.response
            ? new NetworkError(error.response.status, error.response)
            : new NetworkError(500, error)
        )
      }
    )

    // should be after http interceptors

    this.user.session.retrieveTokenFromStore()

    // to finish... setup analytics logic
    Analytics.setup({
      isBrowser: this.isBrowser,
      mediaplayer: this.mediaplayer,
      realtime: this.realtime
    })

    Object.entries(this.#collections).map(([name, model]) => {
      this.#collections[name] = new Collection(model)
    })

    this.on('configured', () => {
      this.isReady = true
    })

    this.on('configurationError', () => {
      this.isReady = false
    })
  }

  #configureNetworkURIs() {
    const {
      apiMode = 'development',
      apiVersion = DEFAULT_APIS_VERSIONS,
      siteId
    } = this.configuration

    const isApiModeObject =
      apiMode &&
      typeof apiMode === 'object' &&
      typeof apiMode.rest === 'string' &&
      typeof apiMode.ws === 'string'
    const isApiModeString = typeof apiMode === 'string'

    if (isApiModeString && !DEFAULT_APIS_ENDPOINTS[apiMode]) {
      throw new ConfigurationError('apiMode', apiMode)
    }

    let restURL = isApiModeString ? DEFAULT_APIS_ENDPOINTS[apiMode].rest : null
    let wsURL = isApiModeString ? DEFAULT_APIS_ENDPOINTS[apiMode].ws : null

    if (isApiModeObject) {
      restURL = this.configuration.apiMode.rest
      wsURL = this.configuration.apiMode.ws
    } else if (Object.keys(DEFAULT_APIS_ENDPOINTS).includes(apiMode)) {
      restURL = DEFAULT_APIS_ENDPOINTS[apiMode].rest
      wsURL = DEFAULT_APIS_ENDPOINTS[apiMode].ws
    } else {
      throw new ConfigurationError('apiMode', apiMode)
    }

    if (!apiVersion) {
      throw new ConfigurationError('apiVersion', apiVersion)
    }

    if (
      !siteId ||
      typeof siteId !== 'string' ||
      /^[a-f\d]{24}$/i.test(siteId) === false
    ) {
      throw new ConfigurationError('siteId', siteId)
    }

    this.http.defaults.baseURL = isApiModeString
      ? `${restURL}${apiVersion}/sites/${siteId}`
      : `${restURL}/sites/${siteId}`

    Realtime.baseURL = isApiModeString ? `${wsURL}${apiVersion}` : wsURL

    return this
  }

  /**
   * @api public
   * @param {object}
   *  @prop {string} login
   *  @prop {string} password
   * @description
   * Create a new Authentication instance
   * @returns {Authenticate}
   */
  authenticate(data, ...more) {
    return this.authentication.login(data, ...more).then(() => {
      // should be non-blocking
      this.doRealtimeConnection().catch((error) => {
        this.emit('authenticationRealtimeError', error)
      })
    })
  }

  /**
   * @api public
   * @param {string} collection
   * @param {array} items
   * initialize a new collection query for
   * the given `collection`
   * @returns {PseudoQuery}
   */
  collection(collection, items = []) {
    if (!this.#collections[collection]) {
      throw new ConceptionError('CONCEPTION_INVALID_COLLECTION', { collection })
    }
    return PseudoQueryFactory(this.#collections[collection], items)
  }

  doRealtimeConnection() {
    const realtimeOptions = this.get('realtime')
    const siteId = this.get('siteId')

    return Realtime.configure(siteId).connect(this.user.session.token)
  }

  /**
   * @api public
   * @param {object} options
   * @description
   * Set the default options (see DEFAULT_CONFIGURATION)
   * Call this function will update the previously configured axios instance
   * @return Promise{this}
   */
  async configure(options) {
    const exceptionHandler = async (error) => {
      if (error.component === 'api') {
        // critical error for an unknown reason
        if (!error.response || !error.response.status) {
          this.emit('configurationError', error)
          throw error

          // if the API response is a 401 OR 403, the identification has failed
          // if 401 : session is not identified
          // if 403 : session is revoked / deleted
        } else if (error.response.status === 401) {
          this.emit('configured', Promise.resolve())

          return this
        } else if (error.response.status === 403) {
          this.user.session.clean() // clean previous session
          this.emit('configured', Promise.resolve())

          // otherwise, the API response seems invalid (500, ...) we need
          // to rethrow the response
        } else {
          this.emit('configurationError', error)
          throw error
        }
      } else if (error.component === 'realtime') {
        if (error.code === 'BAD_RT_AUTHENTICATION') {
          try {
            this.user.session.clean()
            await this.user.session.create()
            this.emit('configured', Promise.resolve())
          } catch (error) {
            exceptionHandler(error)
          }
        }
      } else {
        this.emit('configurationError', error)
      }
    }

    this.set(options)

    if (!this.http) {
      throw new ConfigurationError('http', 'undefined')
    }

    if (this.network.status === false) {
      this.network.once('online', () => {
        this.configure(options)
      })

      // should emit an configurationError event ?
      throw new NetworkError(520)
    }

    if (!options.site) {
      await this.site.fetch(false)
    } else {
      this.site.$rehydrate(options.site)
    }

    if (options.user && options.session) {
      this.user.session.$rehydrate(options.session)
      this.user.session.storeToken()
      try {
        await this.authenticate(options.user, true)
      } catch (error) {
        exceptionHandler(error)
      }
      return this
    }

    const sessionToken = this.user.session.token

    if (sessionToken) {
      try {
        const { item: user } = await this.http.get('/me')
        const { session } = user

        this.user.session.$rehydrate(session)
        this.user.session.token = sessionToken

        // if /me response contains an `id`, this is a valid user response
        // user is well-authenticated
        if (user.id) {
          await this.authenticate(user, true)
        }

        // if the current site is readable by everyone
        // we skip authentication and call doRealtimeCnx
        if (this.site.optAuthentication === false) {
          this.doRealtimeConnection()
            .then(() => {
              this.authentication.loginOnPublicSite()
            })
            .catch((error) => {
              this.emit('authenticationRealtimeError', error)
              this.authentication.loginOnPublicSite()
            })
        }

        this.emit('configured', Promise.resolve())
        return this
      } catch (error) {
        exceptionHandler(error)
      }
    } else {
      try {
        await this.user.session.create()
        this.emit('configured', Promise.resolve())
        this.authentication.loginOnPublicSite()
      } catch (error) {
        exceptionHandler(error)
      }
    }

    return this
  }

  action(element) {
    return this.document(element)
  }

  document(element) {
    return Garbage.find(element)
  }

  documents(pseudoQueryObject) {
    return Garbage.findQuery(pseudoQueryObject).items()
  }

  fetch(pseudoQueryObject, ...props) {
    return Garbage.findQuery(pseudoQueryObject).fetch(...props)
  }

  fetchAll(pseudoQueryObject, ...props) {
    return Garbage.findQuery(pseudoQueryObject).fetchAll(...props)
  }

  item(element) {
    return this.document(element)
  }

  items(pseudoQueryObject) {
    return this.documents(pseudoQueryObject)
  }

  resurrect(pseudoQueryObject) {
    return Garbage.findQuery(pseudoQueryObject)
  }

  /**
   * @api public
   * @alias
   * @see Authenticate#logout
   */
  logout(force = false) {
    return this.authentication.logout(force)
  }

  /**
   * @api public
   * @param {function} cb executed if core.isReady is true
   * @param {number} TIMEOUT default 8000ms
   * @description
   * Execute the `cb` ON THE NEXT TICK function if spoke is well configured
   */
  async ready(cb, cbTimeout = noop, TIMEOUT = 8000) {
    if (this.isReady) {
      try {
        await cb()
      } catch (error) {
        console.error(error)
      }
      return true
    }

    const ctx = setTimeout(() => {
      if (cbTimeout && typeof cbTimeout === 'function') {
        cbTimeout()
      }
    }, TIMEOUT)

    this.on('configured', () => {
      clearTimeout(ctx)
      nextTick(async () => {
        try {
          await cb()
        } catch (error) {
          console.error(error)
        }
      })
    })

    return false
  }

  /**
   * @api public
   * @param {object} payload
   * @description
   * Create a new Register instance
   * @returns {Register}
   */
  register(payload) {
    this.registration = new Register(payload)

    return this.registration
  }

  /**
   * @api public
   * @extends config.set
   * @description
   * Call config.set and apply some necessary updates
   */
  set(options) {
    super.set(options)

    if (this.#activeConfiguration) {
      const needAxiosRefresh =
        this.#activeConfiguration.siteId !== this.configuration.siteId ||
        Object.is(
          this.#activeConfiguration.apiMode,
          this.configuration.apiMode
        ) === false

      if (needAxiosRefresh) {
        this.#configureNetworkURIs()
      }
    }

    // require a detached reference to perform comparisons :)
    this.#activeConfiguration = Object.assign({}, this.configuration)

    return this
  }
}

export default Core
