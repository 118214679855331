var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-voicy-list pa-0",attrs:{"fluid":""}},[(_vm.isLoading || _vm.isLoadingMore)?_c('w-div-loader'):_vm._e(),_vm._v(" "),_c('portal',{attrs:{"to":"before-container"}},[(_vm.hasHighlightedVoice)?_c('div',{staticClass:"d-flex justify-center",class:{
        'mt-0': _vm.$voicer.isDesktop,
        'mb-3': _vm.$voicer.isMobile,
        'pb-4 mb-4': _vm.$voicer.isIE,
      }},[_c('w-voicy-card',{attrs:{"card-options":{
          flat: true,
          tile: true,
        },"content":_vm.highlightedVoice,"highlighted":""}})],1):_vm._e()]),_vm._v(" "),_c('v-container',{class:{
      'pa-0': _vm.$voicer.isDesktopOrTablet,
      'pl-4 pr-4 pt-0': _vm.$voicer.isMobile,
      'pt-4 mt-4': _vm.$voicer.isIE,
    },attrs:{"grid-list-md":""}},[_c('v-layout',{staticClass:"pa-1",attrs:{"row":"","wrap":""}},[(
          _vm.isLoading === false && _vm.isLoadingMore === false && _vm.noContent === true
        )?_c('v-flex',{attrs:{"lg12":""}},[_vm._t("empty")],2):_vm._e(),_vm._v(" "),_vm._l((_vm.voices),function(voice){return _c('v-flex',{key:voice.__uid__,attrs:{"xs12":"","sm6":"","md4":"","lg4":""}},[_c('w-voicy-card',{attrs:{"content":voice}})],1)})],2),_vm._v(" "),_c('div',{staticClass:"w-voicy-list__bottom"},[(_vm.hasMore)?_c('v-btn',{attrs:{"loading":_vm.isLoading || _vm.isLoadingMore,"block":"","color":"primary","depressed":"","large":""},on:{"click":_vm.handleMore}},[_vm._v("\n        "+_vm._s(_vm.$t('see_more'))+"\n      ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }