import $merge from 'lodash.merge'

import {
  findPageFromSlug,
  genAvatarFn,
  getConfig,
  getConfigStrict,
  getCardsImagesGradients,
  getRoutes,
  getShellConfig,
  getSSOProviders,
  getThemeConfig,
  getThemeConfigProperty,
  setContext,
  headTitle,
  headDescription,
  hasDisabledComponent,
  hasLocalAuthModeUniquePassword,
  isThemeDark,
  useLocalAuth,
} from '~/utils/voicer'

export default (context, inject) => {
  const { app } = context
  const { site } = app.store.state.root

  if (!site) {
    throw new Error(`missing site entity in store`)
  }

  setContext(context)

  const isIE = !!window.MSInputMethodContext && !!document.documentMode
  const isIos =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  const isStandalone = !!navigator.standalone

  const $voicer = {
    avatarsLibrary: genAvatarFn(),
    canUseLocalRegistration() {
      return site.optRegistrable === true
    },
    canUseLocalSignin() {
      return useLocalAuth()
    },
    captureException(exception) {
      // todo: send event to brocoli events
      throw exception
    },
    enableGATracker() {
      window[`ga-disable-${getConfig('analytics.ga')}`] = false
    },
    disableGATracker() {
      window[`ga-disable-${getConfig('analytics.ga')}`] = true
    },
    headTitle: headTitle(app.$metadatas.siteHeadTitle),
    headDescription: headDescription(app.$metadatas.siteHeadTitle),
    findPageFromSlug,
    getConfig,
    getConfigStrict,
    getShellConfig,
    getImageGradient() {
      const gradients = getCardsImagesGradients(context)

      return this.isMobile ? gradients.mobile : gradients.desktop
    },
    getLastVisitedRoute() {
      return context.store.getters['root/lastVisitedRoute']
    },
    getRoutes,
    getSSOProviders,
    getThemeConfig,
    getThemeConfigProperty: getThemeConfigProperty(context),
    hasDisabledComponent,
    hasLocalAuthModeUniquePassword,
    enabledComponent: (name) => {
      return hasDisabledComponent(name) === false
    },
    isDarkTheme: isThemeDark(),
    isLightTheme: isThemeDark() === false,
    isOpenSite: function isOpenSite() {
      return site.optAuthentication === false
    },
    isIE,
    isIos,
    isStandalone,
    isMobile: context.isMobile,
    isMobileOrTablet: context.isMobileOrTablet,
    isTablet: context.isTablet,
    isDesktop: context.isDesktop,
    isDesktopOrTablet: context.isDesktopOrTablet,
    isThemeDark,
    site,
    conditioner: function themeConditionExecutor() {
      return new (class Executor {
        properties = undefined

        apply(something) {
          if (typeof something === 'object') {
            this.properties = $merge({}, this.properties, something)
          } else {
            this.properties = something
          }
        }

        ifLight(doSomething) {
          if (isThemeDark() === false) {
            this.apply(doSomething)
          }

          return this
        }

        ifDark(doSomething) {
          if (isThemeDark()) {
            this.apply(doSomething)
          }

          return this
        }

        render() {
          return this.properties
        }
      })()
    },
  }

  inject('voicer', $voicer)
}
