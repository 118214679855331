import { formatDate } from '~/utils/dates'
import { $data, $caption, $metadata } from '~/utils/spoke'

export default {
  computed: {
    contentOptions() {
      return {
        hasBaseline: !!this.baseline,
        hasDate: $metadata(this.content, 'display_date', true),
        supportMarkdown: $metadata(this.content, 'support_markdown', true),
      }
    },
    aspectRatio() {
      return this.$vuetify.breakpoint.mdAndDown ? 1.9 : 3
    },
    id() {
      return this.content.id
    },
    baseline() {
      return $metadata(this.content, 'baseline')
    },
    documents() {
      return $data(this.content, 'documents', [])
    },
    duration() {
      const duration = $data(this.content, 'duration', 0)

      return duration < 60 ? '< 1 min' : (duration / 60).toFixed(0) + ` mins`
    },
    episodes() {
      return this.content.episodes
    },
    hasDocuments() {
      return this.documents.length > 0
    },
    hasLinks() {
      return this.links.length > 0
    },
    hasEpisodes() {
      return this.episodes.length > 0
    },
    isLive() {
      return this.type === 'live'
    },
    isMedia() {
      const type = this.type

      return ['podcast', 'replay', 'live', 'video'].includes(type)
    },
    isPodcast() {
      return this.type === 'audio' || this.type === 'video'
    },
    isReacted() {
      return $data(this.content, 'reactions.isReacted')
    },
    like() {
      return $data(this.content, 'reactions.like', 0)
    },
    links() {
      return $data(this.content, 'links', [])
    },
    publishedAt() {
      const date = new Date($data(this.content, 'publishedAt', new Date()))

      return formatDate(date, this.$i18n.locale)
    },
    hasCommentsSystem() {
      return $data(this.content, 'messager.type') === 'comment'
    },
    comments() {
      return $data(this.content, 'messager.stats.comments', 0)
    },
    commentsText() {
      return this.comments > 1
        ? `${this.comments} commentaires`
        : `Aucun commentaire`
    },
    title() {
      return $data(this.content, 'title')
    },
    type() {
      return $data(this.content, 'type')
    },
    thumbnailImg() {
      const thumbnail = $metadata(this.content, 'thumbnail')

      return thumbnail
        ? $caption(this.content, 'thumbnail')
        : $caption(this.content, 'cards:first')
    },
    cardImg() {
      return (
        $caption(this.content, 'cards:first') ||
        $caption(this.content, 'heading')
      )
    },
    headingImg() {
      const heading = $caption(this.content, 'heading')

      return heading || this.cardImg
    },
    slideshow() {
      return this.content.slideshow
    },
    hasHeadingImg() {
      return $caption(this.content, 'heading') || false
    },
    fulltext() {
      return $data(this.content, 'content')
    },
    summary() {
      let summary = $data(this.content, 'description', '')

      if (!summary) {
        summary = $data(this.content, 'content')
      }

      summary = summary.replace(/\n/gi, '')

      return summary.length > 170 ? summary.substr(0, 167) + '...' : summary
    },
    episodeTitle() {
      const isLive = $data(this.content, 'type') === 'live'
      const defaultValue = isLive ? `Direct` : `Episode 1`

      return $metadata(this.episode, 'title', defaultValue)
    },
    sumEpisodes() {
      return this.episodes.length
    },
    videos() {
      return $metadata(this.content, 'videos', [])
    },
    vimeo() {
      return this.videos.find((video) => video.type === 'vimeo')
    },
  },
}
