<template>
  <v-btn
    :disabled="isLoadingContent || isErrorContent"
    v-bind="props"
    class="w-button-play"
    @click.stop="handleClick(content)"
  >
    <v-progress-circular
      v-if="icon && isLoadingContent"
      indeterminate
      large
      color="grey"
    />
    <v-icon v-else-if="icon && isPlayingContent" key="icon-pause" large>
      pause
    </v-icon>
    <v-icon v-else-if="icon && isError" key="icon-error" large>
      play_arrow
    </v-icon>
    <v-icon v-else-if="icon" key="icon-play" large> play_arrow </v-icon>
    <slot v-if="isLoadingContent" name="loading" />
    <slot v-else-if="!isPlayingContent" name="stopped" />
    <slot v-else-if="isPlayingContent" name="playing" />
    <slot v-else-if="isErrorContent" name="error" />
  </v-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    props: {
      type: Object,
      default: () => ({
        icon: true,
      }),
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      playerContentId: (state) => state.player.content.id,
    }),
    ...mapGetters({
      isError: 'player/isError',
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
    }),
    isSameContent() {
      const content = this.content

      return content.id === this.playerContentId
    },
    isLoadingContent() {
      return this.isSameContent && this.isLoading === true
    },
    isErrorContent() {
      return this.isSameContent && this.error
    },
    isPlayingContent() {
      return this.isSameContent && this.isLoading === false && this.isPlaying
    },
  },

  methods: {
    handleClick(content) {
      if (this.isPlayingContent) {
        this.$store.dispatch('player/ctrlPlayer', 'pause')
      } else {
        this.$store.dispatch('player/changePlayerMode', 'audio')
        this.$store.dispatch('player/changePlayerContent', {
          content,
          noRedirect: this.noRedirect,
        })
        this.$store.dispatch('player/ctrlPlayer', {
          action: 'play',
          content,
        })
      }
    },
  },
}
</script>
