const $clone = require('lodash.clonedeep')
const $merge = require('lodash.merge')
const $set = require('lodash.set')

module.exports = (config) => {
  if (config.useShell) {
    let shellName = config.useShell
    let shellUserConfig = {}

    if (Array.isArray(shellName)) {
      shellName = shellName[0]
      shellUserConfig = shellName[1] || {}
    }

    const shellConfig = require(`../../excentrics/shells/${shellName}/config`)

    // We will save the native user configurations in a subobject
    // called `__usrDefinedConfig`
    // this
    $set(config, '__usrDefinedConfig', $clone(config))

    if (shellConfig && typeof shellConfig.configureBubblecast === 'function') {
      shellConfig.configureBubblecast(config, shellUserConfig)
    }

    if (shellConfig && typeof shellConfig.nuxt === 'object') {
      config.nuxt = $merge(config.nuxt, shellConfig.nuxt)
    } else if (shellConfig && typeof shellConfig.nuxt === 'function') {
      config.nuxt = $merge(config.nuxt, shellConfig.nuxt())
    }
  }
  return config
}
