<i18n>
{
  "en": {
    "like": "like",
    "comments": "No comments | One comment | {count} comment"
  },
  "fr": {
    "like": "favoris",
    "comments": "Aucun commentaire | Un commentaire | {count} commentaires"
  }
}
</i18n>

<template>
  <div
    class="w-card-actions grey--text"
    :class="{ 'text--darken-2': $voicer.isLightTheme }"
  >
    <div v-if="contentOptions.hasDate" class="caption">
      {{ publishedAt }}
    </div>
    <div v-if="isPodcast" class="caption">
      {{ duration }}
    </div>
    <div v-if="$voicer.enabledComponent('wButtonLike')" class="caption">
      {{ like }} {{ $tc('like', like) }}
    </div>
    <div v-if="hasCommentsSystem" class="caption">
      <span v-if="!commentsFulltext">
        <span class="mr-1">
          {{ comments }}
        </span>
        <v-icon :color="iconColor" size="14"> chat </v-icon>
      </span>
      <span v-else>{{ $tc('comments', comments, { count: comments }) }}</span>
    </div>
  </div>
</template>

<script>
import contentInfosMixin from '~/mixins/contentInfos'
import utilsMixin from '~/mixins/utils'

export default {
  mixins: [contentInfosMixin, utilsMixin],
  props: {
    commentsFulltext: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'grey',
    },
  },
}
</script>

<style lang="scss" scoped>
.w-card-actions {
  display: flex;

  div {
    display: flex;
    align-items: center;
  }

  div:not(:first-child) {
    &::before {
      content: '·';
      display: flex;
      padding: 0 5px;
    }
  }

  .w-button-like.v-btn {
    width: 22px;
    height: 22px;
    margin-left: 2px;
    margin-right: -5px;
  }
}
</style>
