import EventEmitter from 'eventemitter3'

import DocumentComment from '../models/brocoli/Comment'
import Collection from './Collection'
import PseudoQuery from './PseudoQuery'
import Realtime from './Realtime'
import Garbage from './Garbage'

class Messager extends EventEmitter {
  #collectionComments = undefined

  #content = undefined

  #conversation = undefined

  /**
   * @type {DocumentComment}
   * if present, the messager instance is a thread
   */
  #comment = undefined

  #isWatching = false

  #pseudoQueryComments = undefined

  #threads = new Map()

  #mounted = false

  constructor(content, conversation, comment = undefined) {
    super()
    this.#content = content
    this.#conversation = conversation

    const collectionComments = new Collection(DocumentComment)

    this.#collectionComments = collectionComments
    this.#pseudoQueryComments = PseudoQuery(this.#collectionComments)

    this.#pseudoQueryComments.use((response) => response.reverse())

    this.#conversation.resource = `contents/${this.#content.id}/conversations/${
      this.#conversation.id
    }/`
    this.#collectionComments.resource = `contents/${
      this.#content.id
    }/conversations/${this.#conversation.id}/comments`

    if (comment) {
      this.#comment = comment
      this.#collectionComments.resource = `contents/${
        this.#content.id
      }/conversations/${this.#conversation.id}/comments/${this.#comment.id}`
    }

    // ensure id is present on content
    this.#content.on('mounted', () => {
      this.#conversation.resource = `contents/${
        this.#content.id
      }/conversations/${this.#conversation.id}/`
      this.#collectionComments.resource = `contents/${
        this.#content.id
      }/conversations/${this.#conversation.id}/comments`

      if (comment) {
        this.#collectionComments.resource = `contents/${
          this.#content.id
        }/conversations/${this.#conversation.id}/comments/${this.#comment.id}`
      }
    })

    Object.keys(this.#conversation.data).forEach((key) => {
      Object.defineProperty(this, key, {
        get() {
          return this.#conversation.$data(key)
        }
      })
    })

    // initialize query builder
    this.#pseudoQueryComments.items(0)
  }

  get comments() {
    return this.#pseudoQueryComments.items()
  }

  get isThread() {
    return !!this.#comment
  }

  #createDocument(data) {
    const document = this.#collectionComments.create({
      __uqid__: this.#pseudoQueryComments.__uqid__,
      ...data
    })

    document.resource = this.#collectionComments.resource

    return document
  }

  get(...params) {
    return this.fetchAll(...params)
  }

  fetch(...params) {
    return this.#pseudoQueryComments.fetch(...params)
  }

  fetchAll(...params) {
    return this.#pseudoQueryComments.fetchAll(...params).on('fetched', (output) => {
      if (this.#mounted === false) {
        this.emit('mounted', output)
        this.#mounted = true
      } else {
        this.emit('updated', output)
      }
      return output
    })
  }

  refresh(...params) {
    return this.fetchAll(...params)
  }

  send(message) {
    const commentDocument = this.#createDocument({ content: message })

    return commentDocument.post({ content: message })
  }

  thread(commentId) {
    if (this.#threads.has(commentId)) {
      return this.#threads.get(commentId)
    }

    const comment = this.comments.findById(commentId)
    const thread = new Messager(
      this.#content,
      this.#conversation,
      Garbage.find(comment)
    )

    this.#threads.set(commentId, thread)

    return thread
  }

  unwatch() {
    const realtimeTopic =
      this.isThread === false
        ? `conversations/${this.#conversation.id}/comments`
        : `conversations/${this.#conversation.id}/comments/${
            this.#comment.id
          }/responses`

    Realtime.unwatch(realtimeTopic)
    this.#isWatching = false

    return this
  }

  destroy() {
    this.unwatch()
    this.emit('destroy')

    Object.keys(this._events).forEach((event) => {
      this.off(event)
    })

    this.#pseudoQueryComments.destroy()

    return this
  }

  watch() {
    if (this.#isWatching) {
      return this
    }

    this.#isWatching = true
    const realtimeTopic =
      this.isThread === false
        ? `conversations/${this.#conversation.id}/comments`
        : `conversations/${this.#conversation.id}/comments/${
            this.#comment.id
          }/responses`

    Realtime.watch(realtimeTopic, (payload) => {
      const { data: comment } = payload

      try {
        const mutedStatProperty =
          this.isThread === false ? 'stats.comments' : 'stats.subcomments'

        this.#conversation.$set(
          mutedStatProperty,
          this.#conversation.$data(mutedStatProperty, 0) + 1
        )

        this.#content.$rehydrate()

        const commentDocument = this.#collectionComments.create({
          __uqid__: this.#pseudoQueryComments.__uqid__,
          ...comment
        })

        this.emit('comment', commentDocument)
      } catch (error) {
        console.error(error)
      }
    })

    return this
  }

  lean(...params) {
    return {
      ...this.#conversation.lean(...params),
      messages: this.#pseudoQueryComments.lean(...params)
    }
  }

  toJSON(...params) {
    return this.lean(...params)
  }
}

export default Messager
