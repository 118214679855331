<i18n>
{
  "en": {
    "listen": "Listen now",
    "error": "Something went wrong",
    "stop": "Stop",
    "error": "Network error"
  },
  "fr": {
    "listen": "Écouter maintenant",
    "error": "Erreur de lecture",
    "stop": "Arrêter la lecture",
    "error": "Erreur de lecture"
  }
}
</i18n>

<template>
  <w-button-play
    :content="content"
    :icon="false"
    :props="{
      outlined: true,
      color: 'primary',
      depressed: true,
      large: true,
      noRedirect: noRedirect,
      ...$attrs,
    }"
    :no-redirect="noRedirect"
  >
    <template v-slot:stopped>
      {{ $t('listen') }} ·&nbsp;
      <span v-if="isLive === false">{{ duration }}</span>
      <span v-else>{{ $t('live') }}</span>
    </template>
    <template v-slot:playing>
      <span v-text="$t('stop')" />
      <v-icon>stop</v-icon>
    </template>
    <template v-slot:error>
      <span v-text="$t('error')" />
      <v-icon>error</v-icon>@
    </template>
    <template v-slot:loading>
      <span v-text="$t('loading')" />
    </template>
  </w-button-play>
</template>

<script>
import WButtonPlay from './index'
import contentInfosMixin from '~/mixins/contentInfos'

export default {
  components: {
    WButtonPlay,
  },
  mixins: [contentInfosMixin],
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    props: {
      type: Object,
      default: () => ({
        icon: true,
      }),
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
