import contentComputedMixin from './contentComputed'
import mediaMixin from './media'

export default {
  mixins: [contentComputedMixin, mediaMixin],
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    episode: {
      type: Object,
      default: () => ({}),
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelect() {
      const content = this.content

      // will autoplay media
      this.$store.dispatch('player/changePlayerContent', {
        content,
        noRedirect: this.noRedirect,
      })

      if (this.$voicer.getConfig('disableAutoplay') === false) {
        if (this.isPlayingContent) {
          this.$store.dispatch('player/ctrlPlayer', 'pause')
        }

        this.$store.dispatch('player/ctrlPlayer', {
          action: 'play',
          content,
        })
      }
    },
    handleSelectWavy() {
      const content = this.content

      this.$store.dispatch('player/changePlayerContent', {
        content,
        noRedirect: true,
      })

      if (this.$voicer.getConfig('disableAutoplay') === false) {
        if (this.isPlayingContent) {
          this.$store.dispatch('player/ctrlPlayer', 'pause')
        } else {
          this.$store.dispatch('player/ctrlPlayer', {
            action: 'play',
            content,
          })
        }
      }
    },
  },
}
