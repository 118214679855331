import { mapGetters, mapState } from 'vuex'

import pageHead from './pageHead'

import WFilters from '~/components/WFilters'
import WVoicy from '~/components/WVoicy'
import WWavies from '~/components/WWavies'

export default {
  components: {
    WFilters,
    WVoicy,
    WWavies,
  },
  mixins: [pageHead],
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
      isPersistantVisible: 'player/isPersistantVisible',
    }),
    ...mapState({
      isLoading: (state) => {
        return ['auth', 'contents'].some((module) => {
          return state[module].isLoading
        })
      },
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
    pageOptions() {
      const pageOptions = this.$voicer.findPageFromSlug(
        this.currentPage,
        'options'
      )

      let highlighted = pageOptions ? pageOptions.highlighted : false

      if (typeof highlighted === 'function') {
        highlighted = highlighted(this.$voicer)
      }

      return {
        highlighted,
        filter: pageOptions ? pageOptions.filter : false,
      }
    },
    hasHighlightedContent() {
      return this.pageOptions.highlighted
    },
  },
  methods: {
    handleLoadMoreContents() {
      const mode = this.currentPage
      const page = this.$voicer.findPageFromSlug(mode)

      this.$store.dispatch('contents/getMore', page)
    },
  },
}
