import MarkdownIt from 'markdown-it'

const handlePlugin = (plugin) => plugin.default || plugin

export default ({ app }, inject) => {
  const md = new MarkdownIt('default', {"html":true,"linkify":true,"breaks":true,"typographer":true})

  md.use(handlePlugin(require('@toycode/markdown-it-class')))

  md.use(handlePlugin(require('markdown-it-container')))

  md.use(handlePlugin(require('markdown-it-attrs')))

  inject('md', md)
}
