const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({}, config, {
  /**
   * common configuration here
   */
  siteId: '5bb4cefc9bf0ac988a025428',
  authMode: ['local'],
  authLocaleLoginFormat: ['phone'],
  enableExperimentalBlocks: true,
  displayRGPDModal: true,
  metadatas: {
    name: `Radio RTA`,
    colorPrimary: '#e0071b',
    supportEmailAddress: 'mdp@radio-rta.fr',
  },
  showCardDescription: true,
  filterVisibleFilters () {
    return filter => filter.slug !== 'format'
  },
  pages: (pager) => {
    return pager
      .update('default', {
        title: 'Accueil',
        hideTitle: true,
        visibleInMenu: true,
        icon: 'home',
        iconVisibleDesktop: true,
        options: {
          highlighted: true,
          filter: true
        }
      })
      .add({
        title: 'Flash Quotidien',
        slug: 'quotidien',
        icon: 'subscriptions',
        router: {
          mode: 'quotidien',
          query: {
            is: 'podcast',
            q: 'Quotidien'
          }
        }
      })
      .add({
        title: 'Émission',
        slug: 'emission',
        icon: 'radio_button_checked',
        router: {
          mode: 'emission',
          query: {
            is: 'podcast',
            q: 'Emission'
          }
        }
      })
      .add({
        title: 'Flash Spécial',
        slug: 'special',
        icon: 'flash_on',
        router: {
          mode: 'special',
          query: {
            is: 'podcast',
            q: 'Special'
          }
        }
      })
      .remove('radios')
      .remove('podcasts')
  },

  drawerNavigation: () => [
    {
      icon: 'settings',
      value: 'settings',
      title: (ctx) => ctx.$t('settings')
    },
    {
      icon: 'info',
      value: 'info',
      title: (ctx) => ctx.$t('app_info'),
      noAction: true,
      disableAutoClose: true,
      items: [
        { type: 'link', title: (ctx) => ctx.$t(`legal_notice`), value: '/data/legal-notice' },
        { type: 'link', title: (ctx) => ctx.$t(`terms_usage_policy`), value: '/data/terms-of-service' },
        { type: 'link', title: (ctx) => ctx.$t(`confidentiality_policy`), value: '/data/personal-data' },
        { type: 'link', title: (ctx) => ctx.$t(`goodwill`), value: '/data/goodwill' },
        {
          type: 'method',
          title: (ctx) => ctx.$t(`versions`),
          value: 'versions',
          onClick: (context) => {
            context.dialogVersions = true
          }
        }
      ]
    },
    {
      type: 'method',
      icon: 'exit_to_app',
      value: 'logout',
      title: (ctx) => ctx.$t('logout'),
      onClick: async (context) => {
        await context.$store.dispatch('auth/logout')
      }
    }
  ],





    theme: {
    dark: false
  }
},
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
