import WCardActions from '../WCardActions'
import { $caption } from '~/utils/spoke'

import WButtonPlay from '~/components/WButtonPlay'

export default {
  components: {
    WButtonPlay,
    WCardActions,
  },

  props: {
    cardOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    __cardOptions() {
      const options = {}

      options.class = 'w-voicy-card accent-text'

      if (!this.hasDisabledPageListen) {
        options.class += ' w-voicer-card--with-cursor'
        options.ripple = { class: 'primary--text' }
      } else {
        // eslint-disable-next-line
        this.handleSelect = (_) => _
        // eslint-disable-next-line
        this.handleSelectWavy = (_) => _
      }

      return Object.assign({}, options, this.cardOptions)
    },
    hasDisabledPageListen() {
      return this.$voicer.getConfig('disablePageListen', false)
    },
    img() {
      return $caption(this.content, 'cards:first')
    },
  },
}
